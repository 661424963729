import React, {useState, useEffect} from 'react'
import Img from 'gatsby-image'
import Swiper from 'react-id-swiper'
import classNames from 'classnames'

import './projectTeaser.scss'
import '../../styles/swiper.css'
import Chevron from '../chevron/chevron'

const ProjectTeaser = ({project}) => {
  const isSlideshow = project.images && project.images.additionalImg.length > 0
  const [swiper, updateSwiper] = useState(null)
  const [sliderPos, setSliderPos] = useState('isStart')

  const transformDate = string => {
    const month = new Date(string).getMonth()
    const year = new Date(string).getFullYear()

    const quarter = Math.ceil((month + 1) / 3)

    return `Q${quarter} ${year}`
  }

  const params = {
    speed: 1000
  }

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  }

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  }

  const handleSlideChange = () => {
    if (swiper !== null) {
      const index = swiper.realIndex
      const length = swiper.slides.length - 1

      if (index === 0) {
        setSliderPos('isStart')
      }
      if (index === length) {
        setSliderPos('isEnd')
      }
      if (index !== length && index !== 0) {
        setSliderPos('')
      }
    }
  }

  useEffect(() => {
    if (swiper !== null) {
      swiper.on('slideChange', handleSlideChange)
    }
  })

  const renderFact = (type, data, suffix = '') => (
    <div className='fact'>
      <div className='type uppercase bold'>
        {type}
      </div>
      <div className='data regular'>
        <span>{data}<span>{` ${suffix}`}</span></span>
      </div>
    </div>
  )

  const renderFacts = classNames => (
    <div className={`${classNames} facts mb-4 font-size-small flex-column col-11 col-md-4`}>
      {project.facts && project.facts.location && renderFact('Ort', project.facts.location)}
      {project.facts && project.facts.finishedAt && renderFact('Fertigstellung', transformDate(project.facts.finishedAt))}
      {project.facts && project.facts.projectCategory && renderFact('Typ', project.facts.projectCategory && project.facts.projectCategory.title)}
      {project.facts && project.facts.squareMeter && renderFact('Gesamtfläche', project.facts.squareMeter, 'qm')}
    </div>
  )

  const renderImages = () => {
    if (!project.images || !project.images.mainImg) return null
    if (isSlideshow) {
      return (
        <div className='slider col-11  mt-2 mt-md-0'>
          <button className='prev ml-3' onClick={goPrev} >
            <Chevron onClick={goPrev} size='small' rotation='right' />
          </button>
          <button className='next mr-3' onClick={goNext}>
            <Chevron onClick={goPrev} size='small' />
          </button>
          <Swiper {...params} getSwiper={updateSwiper}>
            <div>
              <Img
                fluid={project.images.mainImg.asset.fluid}
                className='crop-16by10'
                backgroundColor='lightgray'
              />
            </div>
            {
              project.images.additionalImg.map(img => (
                <div key={img.asset.fluid.src}>
                  <Img
                    fluid={img.asset.fluid}
                    className='crop-16by10'
                    backgroundColor='lightgray'
                  />
                </div>
              ))
            }
          </Swiper>
        </div>
      )
    } else {
      return (
        <>
          {
            project.images.mainImg.asset &&
              <div className='col-11 mt-2 mt-md-0'>
                <Img
                  fluid={project.images.mainImg.asset.fluid}
                  className='crop-16by10'
                  backgroundColor='lightgray'
                />
              </div>
          }
        </>
      )
    }
  }

  return (
    <div className={classNames('project-teaser mb-10 container', sliderPos)}>
      <div className='row'>
        <div className='col-11'>
          <div className='line' />
        </div>
      </div>
      <div className='row'>
        <div className='title col-11 col-md-7'>
          <h3 className='mt-2 mb-6 font-size-large'>{project.title}</h3>
          {
            project.fileDownload &&
              <a
                href={project.fileDownload.asset.url}
                download={project.fileDownload.originalName}
                target='_blank'
                className='expose font-size-medium d-block'
              >
                Exposé
                <Chevron onClick={goPrev} color='secondary' size='small' rotation='down' className='ml-2' />
              </a>
          }
        </div>
        {renderFacts('d-none d-md-flex')}
      </div>
      <div className='row'>
        {renderImages()}
      </div>
      <div className='row d-md-none'>
        {renderFacts()}
      </div>
    </div>
  )
}
export default ProjectTeaser
